import { RedocStandalone } from 'redoc';
import Plataforma from '../documents/plataforma.yaml';
import { useEffect } from 'react';


function SystemDocumentation() {
  useEffect( () => {
    window.onload = function () { 
      document.querySelector('.search-input').placeholder= "Pesquisar";
     } 
  });

    return (
      <div>
        <RedocStandalone 
          specUrl= {Plataforma}
          options={{
            nativeScrollbars: true,
            theme: { 
              colors: { 
                primary: { 
                  main: '#004cff' 
                } 
              } 
            },
          }}
        />
        <style jsx>{`
  
            .sc-eDvSVe {
              background: #fafafa;
              width: 100%;
            }
            .menu-content {
              top: 96px !important;
              height: calc(80vh - 0px) !important;
            }
            .api-content {
            //  top: 22px !important;
            }
            .api-info {
              width: 100%;
            }
          .sc-eDvSVe:nth-child(2){
            background:#fafafa;
            width: 100%;
          }





          .sc-eDvSVe:nth-child(2) div{
             width: 100%;
          }
          /*remover
          .fWrHAO {
            width: calc(100% - 0%);
            padding: 0 0px;
          }
          */




          .sc-eDvSVe:nth-child(3){
              background:#fafafa;
              width: 100%;
          }
          .sc-eDvSVe:nth-child(3) div{
              width: 100%;
          }
          .sc-eDvSVe:nth-child(4){
              background:#fafafa;
              width: 100%;
          }
          .sc-eDvSVe:nth-child(4) div{
              width: 100%;
          }
          .sc-eDvSVe:nth-child(5){
            background:#fafafa;
            width: 100%;
          }
          .sc-eDvSVe:nth-child(5) div{
              width: 100%;
          }
          .sc-eDvSVe:nth-child(6) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(7) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(8) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(9) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(10) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(11) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(12) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(13) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(14) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(15) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(16) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(17) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(18) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(19) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(20) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(21) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(22) div{
            width: 100%;
          }
          .sc-eDvSVe:nth-child(23) div{
            width: 100%;
          }
          p {
            line-height: 1.8;
            font-family: 'Ubuntu';
            color: #707070;
            font-size: 18px;
          }
          h1 {
            color: #004cff !important;
            font-weight: 600 !important;
          }
          h2 {
            color: #707070 !important;
            font-family: Ubuntu !important;
            font-weight: 600 !important;
            line-height: 1.8 !important;
          }
          text {
            color: #188038 !important;
            font-family: Ubuntu !important;
            font-weight: 400 !important;
            line-height: 1.8 !important;
          }
          text2 {
            color: #0000ff !important;
            font-family: Ubuntu !important;
            font-weight: 400 !important;
            line-height: 1.8 !important;
          }

          textSubtitle {
            font-family: Ubuntu !important;
            font-weight: 1000 !important;
            line-height: 1.8 !important;
          }
          
          img,
          video{
            border-radius: 12px; 
            box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 10%);
            margin: 15px 0px;
          } 
      
        
        `}</style>
  
      </div>
    );

  }
  export default SystemDocumentation;